
.main-container {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    padding-top: 4%;
}
.info-container {
    background-color: white;
    width: 50%;
    height: 90%;
    border-radius: 15px;
}
.searchIcon {
    background-color: white;
}
.search-container {
    width: 100%;
    text-align: center;
    margin-top: 5%;
}
.cloud-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-size: 90px;
}
.temp-container {
    width: 100%;
    height: 35%;
    background-color: aqua;
    display: flex;
}
.value-container {
    width: 70%;
    height: 100%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.date-container {
    width: 30%;
    height: 100%;
    background-color: rgb(109, 206, 177);
    color: white;
    display: inline;
    text-align: center;
}
.details-container {
    width: 100%;
    height: 25%;
    background-color: rgb(217, 217, 217);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: black;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.degree {
    font-size: 8vh;
}
.cloud {
    font-size: 8vh;
}
.country {
    font-size: 2vh;
    margin-top: -40%;
}
.country-container {
    display: inline-block;
    padding-bottom: 12%;
}
.date {
    font-size: 5vh;
}
.time {
    font-size: 5vh;
    margin-top: -17%;
}
.commonIcon {
    font-size: 3vh;
    color: rgb(54, 147, 119);
}
.commonInfo {
    font-size: 1vw;
}
.commonText {
    margin-top: -10%;
}

@media only screen and (max-width: 840px) {
    .info-container {
        width: 85%;
    }
}
@media only screen and (max-width: 430px) {
    .info-container {
        width: 98%;
    }
    .search-container {
        margin-top: 18%;
    }
    .main-container {
        margin-top: 3%;
    }
    .degree {
        font-size: 5vh;
    }
    .cloud {
        margin-top: 50%;
        font-size: 4vh;
    }
    .date {
        font-size: 2vh;
    }
    .time{
        font-size: 3vh;
    }
    .date-container {
        padding-top: 9%;
    }
    .commonIcon {
        font-size: 2vh;
    }
    .commonText {
        margin-top: -15%;
        font-size: 1.4vh;
    }
    .country {
        margin-top: -35%;
    }
}